import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Searchbar } from '../components/search-form'

const SearchPage = ({ location }) => {
	const searchText = location.search
	return (
		<Layout>
			<SEO title="Resultados" />
			<h2 className="uk-heading-line">
				<span>Resultados para {decodeURI(searchText.split('q=').pop())}</span>
			</h2>
			<Searchbar currentRefinement={searchText} />
		</Layout>
	)
}

export default SearchPage
